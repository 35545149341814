import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { message } from 'antd';
import RouteChangeListener from './components/RouteChangeListener'
import withLoginedRouteProtection from './components/withLoginedRouteProtection'
import withAdminRouteProtection from './components/withAdminRouteProtection'

import Header from './components/Header';

import { LoginProvider } from './contexts/loginContext';

import { initRem } from './utils/rem';

const Home = lazy(() => import('./pages/home'));
// const Community = lazy(() => import('./pages/community'));
const Create = lazy(() => import('./pages/create'));
const Generate = lazy(() => import('./pages/generate'));
const Removal = lazy(() => import('./pages/removal'));
const FaceSwap = lazy(() => import('./pages/faceSwap'));
const Restore = lazy(() => import('./pages/restore'));
const History = lazy(() => import('./pages/history'));
const Points = lazy(() => import('./pages/points'));
const Account = lazy(() => import('./pages/account'));
const Invites = lazy(() => import('./pages/invites'));
const BalanceLog = lazy(() => import('./pages/balanceLogList'));
const Withdraw = lazy(() => import('./pages/withdraw'));
const Share = lazy(() => import('./pages/share'));
const PaymentWays = lazy(() => import('./pages/paymentWays'));

const New = lazy(() => import('./pages/new'));
const Admin = lazy(() => import('./pages/admin'));

const NotFound = lazy(() => import('./components/NotFound'));
const LoginModal = lazy(() => import('./components/LoginModal'));
const RechargeModal = lazy(() => import('./components/RechargeModal'));



initRem(375);

message.config({
  maxCount: 4,
  duration: 4,
  className: 'custom-global-message',
});

function RedirectToHomeWithOriginalPath({ match }) {
  const history = useHistory();

  const id = match.params.id;
  history.replace({
    pathname: '/',
    state: { id }
  });

  return null;
}

const viewportWidth = window.innerWidth;

function App() {
  const [isMobile, setIsMobile] = useState(viewportWidth < 901);

  useEffect(() => {
    const handleResize = () => {
      initRem(375);
      setIsMobile(viewportWidth < 901)
    };
    handleResize()

    // window.addEventListener('resize', handleResize);

    // 清除监听器
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);


  return (
    <LoginProvider>
      <Router>
        <Header isMobile={isMobile} />
        <Suspense>
          <Switch>
            <Route path="/404" component={NotFound} />
            <Route exact path="/home">
              <Redirect to={'/'} />
            </Route>
            <Route exact path={'/'} component={Home} />
            <Route exact path={'/generate'} component={Generate} />
            <Route exact path={'/create'} component={Create} />
            <Route exact path={'/removal'} component={Removal} />
            <Route exact path={'/restore'} component={Restore} />
            <Route exact path={'/faceSwap'} component={FaceSwap} />
            <Route exact path={'/history'} component={History} />
            <Route exact path={'/points'} component={Points} />
            <Route exact path={'/account'} component={Account} />
            <Route exact path={'/invites'} component={withLoginedRouteProtection(Invites)} />
            <Route exact path={'/withdraw'} component={withLoginedRouteProtection(Withdraw)} />
            <Route exact path={'/share'} component={withLoginedRouteProtection(Share)} />
            <Route exact path={'/balanceDetail'} component={withLoginedRouteProtection(BalanceLog)} />
            <Route exact path={'/payments'} component={PaymentWays} />
            
            <Route exact path={'/new'} component={New} />
            <Route exact path={'/admin'} component={withAdminRouteProtection(Admin)} />
            <Route path="/:id(\d{7})" component={RedirectToHomeWithOriginalPath} />
            <Route path="*">
              <Redirect to={'/404'} />
            </Route>
          </Switch>
          <RouteChangeListener /> 
        </Suspense>
        <LoginModal />
        <RechargeModal />
      </Router>
    </LoginProvider>
  );
}

export default App;
